body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.h-100 {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.red {
  /* background-color: salmon; */
  background: linear-gradient(to bottom left, salmon, deeppink);

}

.d-flex {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

.white-text {
  color: #fff;
}

.blue {
  background: linear-gradient(to bottom right, deepskyblue, indigo);
}

.relative {
  position: "relative"
}

.abs-ball {
  position: absolute;
  height: 170px;
  width: 170px;
  background-color: plum;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.codeblock {
  padding: 1em;
  border-radius: 10px;
  font-family: monospace;
  background-color: darkslategray;
  color: #fff;
}

.gray {
  background: linear-gradient(to bottom left, #16BFFD, #CB3066);

}

.purple {
  background: linear-gradient(to bottom right, #f7ff00, #db36a4);

}

.wiking {
  background: linear-gradient(to bottom left, #673AB7, #8DC26F);

}

.signalparent {
  background-color: black;
  display: flex;
  border-radius: calc(4em + 50px);
  max-width: max-content;
}

.redsignal {
  background-color: red;
  height: 100px;
  width: 100px;
  margin: 2em;
  border-radius: 50px;
}

.yellowsignal {
  background-color: gold;
  height: 100px;
  width: 100px;
  margin: 2em 0em;
  border-radius: 50px; 
}

.greensignal {
  background-color: green;
  height: 100px;
  width: 100px;
  margin: 2em;
  border-radius: 50px;
}


.snapstart {
  scroll-snap-align: start;
  scroll-snap-stop: always;

}


.snapcontainer {
  overflow-y: scroll;
  height: 100vh;
  scroll-snap-type: y mandatory;
}

.abs-center {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
}

.black {
  background-color: black;
}

.abs-cent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.mt {
  margin-top: 3em;
}